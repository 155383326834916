import Swiper, {Pagination, Navigation, EffectFade} from 'swiper';
Swiper.use([ Pagination, Navigation, EffectFade ]);

const reviewsSlider = new Swiper(".reviewsSlider", {
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 25,
    navigation: {
        prevEl: '.reviews__nav .btn-prev',
        nextEl: '.reviews__nav .btn-next',
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true
    },
});
