(function($) {
    $(document).ready(function() {
        let pageNumber = 1, myajax = `//${window.location.host}/wp-admin/admin-ajax.php`,
            paramObj = {
                url: myajax,
                action: 'team_ajax',
                $term_id: '',
            };

        function filters(paramObj) {
            $.ajax({
                url: paramObj.url,
                data: paramObj,
                type: 'post',
                beforeSend() {
                    $('.js-team-members').css({ 'opacity': '.3', 'pointer-events': 'none' });
                },
                success(result) {
                    $('.js-team-members').html(result);
                },
                complete() {
                    $('.js-team-members').css({ 'opacity': '1', 'pointer-events': 'auto' });
                },
                error(result) {
                    dl.log(result);
                }
            });
        }

        $('.js-team-filter').on('change', function() {
            let checkedValues = [];

            // Loop through each checkbox with the class js-team-filter
            $('.js-team-filter:checked').each(function() {
                // Push the value of the checked checkbox into the array
                checkedValues.push($(this).val());
            });
            paramObj.term_id = checkedValues;
            filters(paramObj);
        });
    });
})(jQuery);
