$(document).ready(function(){
    let backArray = ['default'],
        prevElement = 'default',
        percent = 0;

    function progressValuePlus() {
        percent = percent + 25;
        $('.js-progress-line').css('width', `${percent}%`);
        $('.js-progress-status').html(percent);
    }

    function progressValueMinus() {
        percent = percent - 25;
        $('.js-progress-line').css('width', `${percent}%`);
        $('.js-progress-status').html(percent);
    }

    function checkNextButtonStatus() {
        if(backArray.length > 4) {
            $('.js-it-next').css('display', 'none');
        } else {
            $('.js-it-next').css('display', 'block');
        }
    }
    checkNextButtonStatus();

    function checkBackButtonStatus() {
        dl.log(backArray);
        dl.log(backArray.length);
        if(backArray.length <= 1) {
            $('.js-it-back').css('display', 'none');
        } else {
            $('.js-it-back').css('display', 'block');
        }
    }
    checkBackButtonStatus();

    $(document).on('click', '.js-it-option', function(){
        $('.js-it-option').removeClass('active');
        $(this).addClass('active');
        $('.js-it-next').attr('data-next', $(this).data('id'));
        $('.js-it-back').attr('data-back', $(this).parent().parent().data('id'));
        $('.js-it-next').removeAttr("disabled");

        /**/
        $('.js-it-item').removeClass('active');
        let nextId = $(".js-it-next").attr('data-next');
        $(".js-it-item").each(function(index, element){
            let itemId = $(element).data("id");
            if(itemId == nextId) {
                $(element).addClass("active");
            }
        });

        let prevId = $('.js-it-back').attr('data-back');
        backArray.push(prevId);

        $('.js-it-next').attr("disabled", "disabled");
        checkBackButtonStatus();
        checkNextButtonStatus();
        progressValuePlus();
    });

    $(document).on('click', '.js-it-next', function(){
        $('.js-it-option').removeClass('active')
        $('.js-it-item').removeClass('active');
        let nextId = $(".js-it-next").attr('data-next');
        $(".js-it-item").each(function(index, element){
            let itemId = $(element).data("id");
            if(itemId == nextId) {
                $(element).addClass("active");
            }
        });

        let prevId = $('.js-it-back').attr('data-back');
        backArray.push(prevId);

        $('.js-it-next').attr("disabled", "disabled");
        checkBackButtonStatus();
        checkNextButtonStatus();
        progressValuePlus();
    });

    $(document).on('click', '.js-it-back', function(){
        $('.js-it-option').removeClass('active')
        $('.js-it-item').removeClass('active');
        let backId = backArray.slice(-1)[0];
        $(".js-it-item").each(function(index, element){
            let itemId = $(element).data("id");
            if(itemId == backId) {
                $(element).addClass("active");
            }
        });
        backArray.pop();
        checkBackButtonStatus();
        checkNextButtonStatus();
        progressValueMinus();
    });
});
