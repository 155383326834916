$(function () {
    function initAccordion(trigger, activeItemFirst, activeItemClass, allItemsHidden) {
        trigger.on('click', function H() {
            let self = $(this);
            trigger.not(self).siblings()
                .slideUp(400);
            self.parent().siblings().removeClass(`${activeItemClass}`);
            if (self.parent().hasClass(`${activeItemClass}`)) {
                self.siblings().slideUp(400);
                self.parent().removeClass(`${activeItemClass}`);
            } else {
                $(this).siblings()
                    .slideDown(400);
                $(this).parent().addClass(`${activeItemClass}`);
            }
        });

        if(allItemsHidden != true) {
            if (activeItemFirst) {
                activeItemFirst.first().find(trigger)
                    .siblings()
                    .slideDown(400);
                activeItemFirst.first().addClass(`${activeItemClass}`);
            }
        }
    }

    initAccordion($('.js-trigger-accordion'), $('.js-accordion-item'), 'active');
});
