// import styles
import '@scss/main.scss';
import '@lib/scss/base.lib.scss';
// require main js file
require('@js/index');
require('@js/video-bg');
require('@js/video-play');
require('@js/story-block');
require('@js/faq');
require('@js/ajax-team');
require('@js/ajax-blog');
require('@js/swiper');
require('@js/interactive-tool');
require('@js/scroll-to');
require('@js/header');
require('@js/services');
require('@js/select');
require('@js/header-menu');
require('@js/form');
// require('@js/quote-button');
// require('@js/video-button');

