//Video background
let timeoutId,
    $videoBgAspect = $(".videobg-aspect"),
    $videoBgWidth = $(".videobg-width"),
    videoAspect = $videoBgAspect.outerHeight() / $videoBgAspect.outerWidth();

function videobgEnlarge() {
    let windowAspect = $(window).height() / $(window).width();
    if(windowAspect > videoAspect) {
        $videoBgWidth.width(`${windowAspect / videoAspect * 100}%`);
    } else {
        $videoBgWidth.width(`${100}%`);
    }
}

$(window).resize(function() {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(videobgEnlarge, 100);
});

$(function() {
    videobgEnlarge();
});
