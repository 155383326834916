$('#nav-icon').click(function H() {
    $(this).toggleClass('change-icon');
    $('.js-mobile-nav').toggleClass('nav-open');
    $('body').toggleClass('body-overflow');
});

$('.js-dropdown-btn').click(function H(e) {
    e.preventDefault();
    $(this).parent().parent()
        .toggleClass('submenu-open');
    $(this).parent().parent().find('.sub-menu')
        .slideToggle(300);
});
