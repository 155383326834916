if(document.querySelector('.cam__video-overlay')) {
    document.querySelector('.cam__video-overlay').addEventListener('click', function H() {
        let __this = this;
        dl.log(__this.parentNode);
        dl.log(__this.parentNode.querySelector('.cam__video-overlay'));
        if(__this.parentNode.querySelector('.cam__video-foreground').classList.contains('youtube-vid')) {
            let src = `https://www.youtube.com/embed/${__this.parentNode.querySelector('.youtube-vid').dataset.src}?autoplay=1;&feature=oembed&amp;controls=1&amp;hd=1&amp;autohide=1&amp;mute=0`;
            __this.parentNode.querySelector('.youtube-vid iframe').setAttribute('src', src);
        }
        if(__this.parentNode.querySelector('.cam__video-foreground').classList.contains('vimeo-vid')) {
            let src = `${__this.parentNode.querySelector('.vimeo-vid').dataset.src}?autoplay=1&loop=0&muted=0&playinline=1&controls=1`;
            __this.parentNode.querySelector('.vimeo-vid iframe').setAttribute('src', src);
        }
        if(__this.parentNode.querySelector('.cam__video-foreground').classList.contains('mp4-vid')) {
            let src = `${__this.parentNode.querySelector('.mp4-vid source').getAttribute('src')}`;
            __this.parentNode.querySelector('.mp4-vid').innerHTML = `
                    <video width="100%" height="100%" controls="controls" autoplay>
                        <source src="${src}"
                            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                    </video>
                `;
        }
        setTimeout(() => {
            __this.style.display = 'none';
        }, 700);
    });
}

if(document.querySelector('.story__video-overlay')) {
    document.querySelector('.story__video-overlay').addEventListener('click', function H() {
        let __this = this;
        dl.log(__this.parentNode);
        dl.log(__this.parentNode.querySelector('.story__video-overlay'));
        if(__this.parentNode.querySelector('.story__video-foreground').classList.contains('youtube-vid')) {
            let src = `https://www.youtube.com/embed/${__this.parentNode.querySelector('.youtube-vid').dataset.src}?autoplay=1;&feature=oembed&amp;controls=1&amp;hd=1&amp;autohide=1&amp;mute=0`;
            __this.parentNode.querySelector('.youtube-vid iframe').setAttribute('src', src);
        }
        if(__this.parentNode.querySelector('.story__video-foreground').classList.contains('vimeo-vid')) {
            let src = `${__this.parentNode.querySelector('.vimeo-vid').dataset.src}?autoplay=1&loop=0&muted=0&playinline=1&controls=1`;
            __this.parentNode.querySelector('.vimeo-vid iframe').setAttribute('src', src);
        }
        if(__this.parentNode.querySelector('.story__video-foreground').classList.contains('mp4-vid')) {
            let src = `${__this.parentNode.querySelector('.mp4-vid source').getAttribute('src')}`;
            __this.parentNode.querySelector('.mp4-vid').innerHTML = `
                    <video width="100%" height="100%" controls="controls" autoplay>
                        <source src="${src}"
                            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                    </video>
                `;
        }
        setTimeout(() => {
            __this.style.display = 'none';
        }, 700);
    });
}

if(document.querySelector('.sh-yt__video-overlay')) {
    document.querySelector('.sh-yt__video-overlay').addEventListener('click', function H() {
        let __this = this;
        if(__this.parentNode.querySelector('.sh-yt__video-foreground').classList.contains('youtube-vid')) {
            let src = `https://www.youtube.com/embed/${__this.parentNode.querySelector('.youtube-vid').dataset.src}?autoplay=1;&feature=oembed&amp;controls=1&amp;hd=1&amp;autohide=1&amp;mute=0`;
            __this.parentNode.querySelector('.youtube-vid iframe').setAttribute('src', src);
        }
        setTimeout(() => {
            __this.style.display = 'none';
        }, 700);
    });
}
