document.addEventListener("DOMContentLoaded", () => {
    const $header = document.querySelector('.js-page-header'),
        $page = document.querySelector('.js-content');

    let lastScrollTop = 0;

    function stickyHeader() {
        const $topBarHeight = document.querySelector('.js-pht').offsetHeight;
        // if($(window).scrollTop() > $headerHeight) {
        //     $header.classList.add('sticky');
        //     $header.style.transform = `translateY(-${$topBarHeight}px)`;
        // } else {
        //     $header.classList.remove('sticky');
        //     $header.style.transform = `translateY(0)`;
        // }

        let currentScroll = window.pageYOffset || document.documentElement.scrollTop;
        // Get current scroll position

        if (currentScroll > lastScrollTop){
            // If the current scroll position is greater than the last scroll position, the user is scrolling down
            $header.classList.add('sticky');
            $header.style.transform = `translateY(-${$topBarHeight}px)`;
        } else {
            // If the current scroll position is less than the last scroll position, the user is scrolling up
            $header.classList.remove('sticky');
            if($('body').hasClass('admin-bar')) {
                $header.style.transform = `translateY(32px)`;
            } else {
                $header.style.transform = `translateY(0)`;
            }
        }
        // Update lastScrollTop with the current scroll position
        lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // For Mobile or negative scrolling
    }

    function marginTop() {
        dl.log(document.querySelector('.js-page-header').offsetHeight);
        requestAnimationFrame(() => {
            $page.style.paddingTop = `${document.querySelector('.js-page-header').offsetHeight}px`;
        });
    }

    window.addEventListener('load', () => { stickyHeader(); marginTop(); });
    window.addEventListener('resize', () => { stickyHeader(); marginTop(); });
    document.addEventListener('scroll', () => { stickyHeader(); marginTop(); });
});
