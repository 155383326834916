$(document).ready(function() {
    let scrollPosition = 0;
    $('.js-story-item:first').addClass('active');
    // При кліку на елемент з класом js-story-btn
    $('.js-story-btn').on('click', function() {
        // Знаходимо блок з класом js-story-item, який має клас active
        // let activeStory = $('.js-story-item.active');
        //
        // // Знаходимо наступний блок з класом js-story-item (або перший, якщо поточний останній)
        // let nextStory = activeStory.next('.js-story-item');
        // if (nextStory.length === 0) {
        //     nextStory = $('.js-story-item:first');
        // }

        // Додаємо клас active до наступного блоку
        // nextStory.addClass('active');

        // Оновлюємо стан кнопки
        // dl.log($('.js-story-item').length);
        // dl.log($('.js-story-item.active').length);
        // // Перевірка, чи всі блоки мають клас active
        // let allActive = $('.js-story-item').length === $('.js-story-item.active').length;
        // // Додавання або видалення класу not-active в залежності від умови
        // $('.js-story-btn').toggleClass('not-active', allActive);
        scrollPosition = $(window).scrollTop(); // Store current scroll position
        $('.js-story-item').addClass('active');
        $('.js-story-btn').addClass('not-active');
        $(window).scrollTop(scrollPosition); // Scroll back to stored position
    });
});
