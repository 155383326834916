(function($) {
    $(document).ready(function() {
        let pageNumber = 1, type = '', myajax = `//${window.location.host}/wp-admin/admin-ajax.php`,
            paramObj = {
                url: myajax,
                action: 'posts_ajax',
                postPage: '',
                category: '',
                tag: '',
                author: '',
            };

        function filters(paramObj) {
            $.ajax({
                url: paramObj.url,
                data: paramObj,
                type: 'post',
                beforeSend() {
                    $('.js-posts').css({ 'opacity': '0.3', 'pointer-events': 'none' });
                },
                complete() {
                    $('.js-posts').css({ 'opacity': '1', 'pointer-events': 'auto' });
                    dl.log($('.js-posts-dp').data('pages'));
                    if(($('.js-posts-dp').data('pages') === pageNumber) || $('.js-posts-dp').data('pages') == 1 ) {
                        $('.js-load-more').css('display', 'none');
                        $('.js-load-more-cat').css('display', 'none');
                        $('.js-load-more-tag').css('display', 'none');
                        $('.js-load-more-author').css('display', 'none');
                    } else {
                        $('.js-load-more').css('display', 'inline-block');
                        $('.js-load-more-cat').css('display', 'inline-block');
                        $('.js-load-more-tag').css('display', 'inline-block');
                        $('.js-load-more-author').css('display', 'inline-block');
                    }
                },
                success(result) {

                    if(type == 'loadmore') {
                        $('.js-posts').append(result);
                    } else {
                        $('.js-posts').html(result);
                    }
                },
                error(result) {
                    dl.log(result);
                }
            });
        }

        $(document).on('click', '.js-load-more', function H() {
            pageNumber++;
            // query args
            type = 'loadmore';
            paramObj.category = $('.js-posts-category.active').data('value');
            paramObj.postPage = pageNumber;
            filters(paramObj);
            dl.log(pageNumber)
        });

        $(document).on('click', '.js-load-more-cat', function H() {
            pageNumber++;
            // query args
            type = 'loadmore';
            paramObj.category = $('.js-posts-category.active').data('value');
            paramObj.postPage = pageNumber;
            filters(paramObj);
        });

        $(document).on('click', '.js-load-more-tag', function H() {
            pageNumber++;
            // query args
            type = 'loadmore';
            paramObj.tag = $('.js-posts-tag.active').data('value');
            paramObj.postPage = pageNumber;
            filters(paramObj);
        });

        $(document).on('click', '.js-load-more-author', function H() {
            pageNumber++;
            // query args
            type = 'loadmore';
            paramObj.author = $('.js-posts-author.active').data('value');
            paramObj.postPage = pageNumber;
            filters(paramObj);
        });

        $('.js-posts-category').on('click', function H(e) {
            e.preventDefault();
            type = 'filter';
            $('.js-posts-category').removeClass('active');
            $(this).addClass('active');
            paramObj.category = $(this).data('value');
            paramObj.postPage = 1;
            pageNumber = 1;
            filters(paramObj);
        });

        $('.js-filter-topic select').on('change', function H(e) {
            e.preventDefault();
            type = 'filter';
            paramObj.category = $('.js-filter-topic select').val();
            paramObj.postPage = 1;
            pageNumber = 1;
            filters(paramObj);
        });
    });
})(jQuery);
